import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Функция для очистки имени файла от специальных символов
const cleanFileName = (fileName) => {
  // Удаляем все символы, кроме букв, цифр и точек
  return fileName.replace(/[^a-zA-Z0-9.]/g, '');
};

const BubbleRequest = () => {
  const [releases, setReleases] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = '91ce7d70742ec648cced1a3766f19691';

        const response = await fetch('https://paradigm-music.ru/api/1.1/obj/releases?constraints=[{"key":"label","constraint_type":"equals","value":"METRIKA MUSIC"},{"key":"StatusRelease","constraint_type":"equals","value":"Выпущенные"},{"key":"master_release","constraint_type":"equals","value":"yes"},{"key":"release-date","constraint_type":"is_not_empty"}]&sort_field=release-date&descending=true', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const cloudFrontUrl = 'https://d15lyydhgewrm7.cloudfront.net';

        // Форматирование URL изображений и удаление специальных символов
        const formattedReleases = data.response.results.map(release => {
          const originalCoverArtUrl = release['cover-art'];
          const decodedUrl = decodeURIComponent(originalCoverArtUrl);
          const filename = decodedUrl.split('/').pop();
          
          // Очистка имени файла
          const cleanFilename = cleanFileName(filename);
          const formattedCoverArtUrl = `${cloudFrontUrl}/images/${encodeURIComponent(cleanFilename)}`;

         // console.log('Original Image URL:', originalCoverArtUrl); // Лог оригинальных ссылок
         // console.log('Cleaned Image URL:', formattedCoverArtUrl); // Лог очищенных ссылок
          
          return {
            ...release,
            'cover-art': formattedCoverArtUrl
          };
        });

        setReleases(formattedReleases);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const PrevArrow = (props) => (
    <div onClick={props.onClick} className='prevArrow'>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </div>
  );

  const NextArrow = (props) => (
    <div onClick={props.onClick} className='nextArrow'>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
  );

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
          initialSlide: 0
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          initialSlide: 0
        }
      }
    ]
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <p className="pageTitles">MUSIC</p>
      <Slider {...settings}>
        {releases.map((release, index) => (
          <div key={index} className="cover">
            <a href={release['smart-link']} target="_blank" rel="noopener noreferrer">
              <img src={release['cover-art']} alt={`Cover ${release.title}`} style={{ width: '200px', height: '200px', marginBottom: '10px'}} />
            </a>
            <p className='release-title'>{Array.isArray(release['artist-names_txt']) ? release['artist-names_txt'].join(', ') : release['artist-names_txt']} - {release.title}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BubbleRequest;
